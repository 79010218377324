/**
 * Helpers
 */
import {
  fromBreakpoint,
  onVisibilityChange, toBreakpoint
} from './helpers'

/**
 * Web standards
 */
import './cookieconsent'
import './softscroll'
import './lazyload'
import 'alpinejs/dist/cdn.min.js';
import 'slick-carousel';
// import Splide from '@splidejs/splide';

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector( '.wpcf7-form' );
if ( wpcf7forms ) {
  const jquery = import( 'jquery' );
  global.jQuery = require( 'jquery' );
  window.jQuery = require( 'jquery' );
}

/**
 * Add scripts or styles based on loaded DOM
 */
addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('div[data-modal-target]').length > 0) {
    modal.init();
  }
})


/**
 * Dynamic import scripts
 */
const elements = [
  '#tegelsCalculator',
  '#reviewsSlider',
  '#productdetailSlider',
  '#productSlider',
  '[data-fancybox="gallery"]',
];

[].forEach.call(elements, (element) => {
  if (element !== '') {
    if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {
      const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

        if (element === '#tegelsCalculator') {
          const tegelsCalculator = import('./tegels.calculator');
        }

        if (element === '#reviewsSlider') {
          const reviewsSlider = import('./reviews.slider')
        }

        if (element === '#productdetailSlider') {
          const productdetailSlider = import('./productdetail.slider')
        }

        if (element === '#productSlider') {
          const productSlider = import('./product.slider')
        }
        if(element === '[data-fancybox="gallery"]') {
          const fancybox = import('./fancybox')
        }

      })

      if (window.addEventListener) {
        addEventListener('DOMContentLoaded', lazyloadHandler, false);
        addEventListener('load', lazyloadHandler, false);
        addEventListener('scroll', lazyloadHandler, false);
        addEventListener('resize', lazyloadHandler, false);
      } else if (window.attachEvent) {
        attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
        attachEvent('onload', lazyloadHandler);
        attachEvent('onscroll', lazyloadHandler);
        attachEvent('onresize', lazyloadHandler);
      }
    }
  }
});